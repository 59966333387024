var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "asset-card relative rounded-lg border-solid",
      class: {
        "border-3": _vm.selected,
        "border-primary": _vm.selected,
        "border-grey": !_vm.selected,
        "border-1": !_vm.selected
      },
      on: {
        click: function($event) {
          return _vm.$emit("select", _vm.asset)
        }
      }
    },
    [
      _c("img", { attrs: { src: _vm.asset.url } }),
      _c("SKTooltip", { attrs: { text: _vm.asset.name } }, [
        _c("span", { staticClass: "block p-3 text-xs" }, [
          _vm._v(_vm._s(_vm.asset.name))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }