var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sktooltip-container" }, [
    _c(
      "div",
      {
        ref: "trigger",
        staticClass: "cursor-pointer",
        on: {
          mouseover: function($event) {
            _vm.showTooltip = true
          },
          mouseout: function($event) {
            _vm.showTooltip = false
          }
        }
      },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTooltip,
            expression: "showTooltip"
          }
        ],
        ref: "tooltip",
        staticClass: "sktooltip text-xs",
        style: { top: _vm.triggerY + "px", left: _vm.triggerX + "px" }
      },
      [_vm._v(" " + _vm._s(_vm.text) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }