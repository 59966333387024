var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assetList.length
    ? _c(
        "div",
        {
          staticClass:
            "flex flex-column vs-con-textarea my-2 rounded-lg p-5 mt-5"
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-row flex-wrap w-full items-center" },
            _vm._l(_vm.assets, function(asset) {
              return _c(
                "div",
                { key: asset.key, staticClass: "w-1/4 py-3 px-2" },
                [
                  asset.languages
                    ? _c("AssetMultilanguage", {
                        attrs: {
                          asset: asset,
                          selected: asset.key === _vm.selected
                        },
                        on: { select: _vm.handleSelect }
                      })
                    : _c("Asset", {
                        attrs: {
                          asset: asset,
                          selected: asset.key === _vm.selected
                        },
                        on: { select: _vm.handleSelect }
                      })
                ],
                1
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }