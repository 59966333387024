<template>
    <div
        class="asset-card relative rounded-lg border-solid"
        :class="{ 'border-3': selected, 'border-primary': selected, 'border-grey': !selected, 'border-1': !selected }"
        @click="$emit('select', asset)"
    >
        <img :src="asset.url"/>
         <SKTooltip :text="asset.name">
            <span class="block p-3 text-xs">{{ asset.name }}</span>
        </SKTooltip>
    </div>
</template>

<script>
import SKTooltip from "@/modules/Shared/Components/SKTooltip";

export default {
     components: {
        SKTooltip,
    },
    props: {
        asset: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
}
</script>
<style>
    .asset-card {
        box-shadow: 0px 3.07029390335083px 6.14058780670166px 0px rgba(0, 1, 27, 0.16);
        min-height: 280px;
        overflow: hidden;
    }
    .asset-card img {
        width: 100%;
        height: auto;
        object-fit: scale-down;
        height: 237px;
        @apply bg-background-grey;
    }
</style>
