<template>
  <div class="sktooltip-container">
    <div ref="trigger" class="cursor-pointer" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <slot></slot>
    </div>
    <div ref="tooltip" class="sktooltip text-xs" v-show="showTooltip" :style="{ top: triggerY + 'px', left: triggerX + 'px' }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SKTooltip",
  data() {
    return {
      showTooltip: false,
      triggerX: 0,
      triggerY: 0,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    document.body.appendChild(this.$refs.tooltip)
  },
  updated() {
    if (this.showTooltip) {
        let triggerWidth = this.$refs.trigger.getBoundingClientRect().width
        let tooltipWidth = this.$refs.tooltip.getBoundingClientRect().width
        this.triggerX = this.$refs.trigger.getBoundingClientRect().left + window.scrollX - ((tooltipWidth - triggerWidth) / 2)
        this.triggerY = this.$refs.trigger.getBoundingClientRect().top + window.scrollY - 20
    }
  }
};
</script>

<style>
.sktooltip {
    @apply absolute text-white p-1 rounded-md bg-black;
    z-index: 99999;
}
</style>