<template>
    <div
        class="asset-card rounded-lg relative border-solid"
        :class="{ 'border-3': selected, 'border-primary': selected, 'border-grey': !selected, 'border-1': !selected }"
        @click="$emit('select', asset)"
    >
        <div>
            <div class="absolute right-0 top-0 mr-2 mt-2">
                <SKTooltip :text="asset.languages.map(l => l.acronym).join(', ')">
                    <div class="multilanguage-icon-wrapper">
                        <feather-icon icon="GlobeIcon" svgClasses="h-7 w-7 stroke-current text-primary" class="align-middle"/>
                    </div>
                </SKTooltip>
            </div>
            <img :src="mainLanguage.url"/>
        </div>

         <SKTooltip :text="asset.key">
            <span class="block p-3 text-xs">{{ asset.key }}</span>
        </SKTooltip>
    </div>
</template>

<script>
import SKTooltip from "@/modules/Shared/Components/SKTooltip";

export default {
     components: {
        SKTooltip,
    },
    props: {
        asset: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        mainLanguage() {
            return this.asset.languages.find(l => l.main)
        }
    }
}
</script>
<style>
    .multilanguage-icon-wrapper {
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.7);
    }
    .asset-card {
        box-shadow: 0px 3.07029390335083px 6.14058780670166px 0px rgba(0, 1, 27, 0.16);
        min-height: 280px;
        overflow: hidden;
    }
    .asset-card img {
        width: 100%;
        height: auto;
        object-fit: scale-down;
        height: 237px;
        @apply bg-background-grey;
    }
</style>
