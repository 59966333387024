<template>
    <div v-if="assetList.length" class="flex flex-column vs-con-textarea my-2 rounded-lg p-5 mt-5">
        <div class="flex flex-row flex-wrap w-full items-center">
            <div class="w-1/4 py-3 px-2" v-for="asset in assets" :key="asset.key">
                <AssetMultilanguage
                    v-if="asset.languages"
                    :asset="asset"
                    :selected="asset.key === selected"
                    @select="handleSelect"
                />
                <Asset
                    v-else
                    :asset="asset"
                    :selected="asset.key === selected"
                    @select="handleSelect"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Asset from '@/modules/Apps/Components/resources/gallery/Asset'
import AssetMultilanguage from "@/modules/Apps/Components/resources/gallery/AssetMultilanguage.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AssetMultilanguage,
        Asset,
    },
    props: {
        assetList: {
            type: Array,
            required: true
        },
        grouped: {
            type: Boolean,
            default: false
        },
        selected: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleSelect(asset) {
            this.$emit('select', asset)
        }
    },
    computed: {
        ...mapGetters(['spotlioLanguages']),
        assets() {
            const processedAssets = this.assetList.map(asset => {
                const url = new URL(asset)
                const name = url.pathname.split('/').pop()

                return {
                    key: name.split('.')[0],
                    url: url.toString(),
                    name: name,
                }
            })

            if (!this.grouped) {
                return processedAssets
            }

            const assets = []

            const assetsGroupedByKey = Object.groupBy(processedAssets, ({key}) => {
                const suffix = key.substring(key.length - 2)
                const finalKey = key.substring(0, key.length-3)
                return this.spotlioLanguages.map(language => language.acronym).includes(suffix) ? finalKey : key
            })

            for (const [key, value] of Object.entries(assetsGroupedByKey)) {
                if (value.length === 1) {
                    assets.push(value[0])
                } else {
                    assets.push({
                        key: key,
                        languages: value.map(language => {
                            const acronym = language.key.substring(language.key.length-2)
                            return {
                                name: language.name,
                                url: language.url,
                                acronym: acronym,
                                main: acronym === 'en'
                            }
                        })
                    })
                }
            }

            return assets
        },

    },
}
</script>
