var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "asset-card rounded-lg relative border-solid",
      class: {
        "border-3": _vm.selected,
        "border-primary": _vm.selected,
        "border-grey": !_vm.selected,
        "border-1": !_vm.selected
      },
      on: {
        click: function($event) {
          return _vm.$emit("select", _vm.asset)
        }
      }
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "absolute right-0 top-0 mr-2 mt-2" },
          [
            _c(
              "SKTooltip",
              {
                attrs: {
                  text: _vm.asset.languages
                    .map(function(l) {
                      return l.acronym
                    })
                    .join(", ")
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "multilanguage-icon-wrapper" },
                  [
                    _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "GlobeIcon",
                        svgClasses: "h-7 w-7 stroke-current text-primary"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("img", { attrs: { src: _vm.mainLanguage.url } })
      ]),
      _c("SKTooltip", { attrs: { text: _vm.asset.key } }, [
        _c("span", { staticClass: "block p-3 text-xs" }, [
          _vm._v(_vm._s(_vm.asset.key))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }