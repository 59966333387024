import axios from '@/axios.js'

export default {
    getHomeLayoutList: async (parameters) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/home-layouts`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    createHomeLayout: async (homeLayoutUuid, body) => {
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/home-layouts/${homeLayoutUuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    updateHomeLayout: async (homeLayoutUuid, body) => {
        try {
            const response = await axios.editorapi.patch(`/v4/app-configurator/home-layouts/${homeLayoutUuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
}
